import * as React from "react";
import {useEffect, useState} from "react";

import '../styles/styles.scss'
import {AnimateSharedLayout, motion, useViewportScroll} from "framer-motion";

const colors = [
  ["#42C0FF", "#6633FF"],
  ["#1CEEBD", "#5BA4FF"],
  ["#6FFFE9", "#5BC0BD"],
  ["#FF9034", "#FE337B"],
  ["#BE4AA8", "#1CEEBD"],
  ["#FFC032", "#FF9034"],
];

const IndexPage = () => {
  const [randomNumber, setRandomNumber] = useState(0);

  const [scrolledTop, setScrolledTop] = useState(true);

  const [opacity1, setOpacity1] = useState(0);
  const [opacity2, setOpacity2] = useState(0);

  const {scrollY} = useViewportScroll();

  useEffect(() => {
    setRandomNumber((Math.floor(Math.random() * (colors.length))));
  }, []);

  useEffect(() => {
    setScrolledTop(scrollY.get() === 0);
    setOpacity1(scrollY.get() > 500 ? 1 : 0);
    setOpacity2(scrollY.get() > 1000 ? 1 : 0);
  })

  useEffect(() => {
    const reactToScroll = () => {
      setScrolledTop(scrollY.get() === 0);
      setOpacity1(scrollY.get() > 500 ? 1 : 0);
      setOpacity2(scrollY.get() > 1000 ? 1 : 0);
    }

    const unsubscribe = scrollY.onChange(reactToScroll);

    return () => {
      unsubscribe();
    }
  }, [scrollY]);

  const scrollDown = () => {
    window.scrollTo(0, 501)
  }

  return (<>
    <AnimateSharedLayout>
      <motion.div
        layout
        style={{
          height: "2000px",
          padding: "150px 0 0",
          position: "relative"
        }}>

        <motion.div
          layout
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: colors[randomNumber][0]
          }}>
          <motion.div
            style={{
              fontSize: "50px",
              textAlign: "center",
            }}
            animate={{opacity: opacity1}}
          >
            Site under construction.
          </motion.div>

          <motion.div
            style={{
              fontSize: "24px",
              textAlign: "center",
              position: "absolute",
              bottom: "50px",
              left: "0",
              right: "0"
            }}
            animate={{opacity: opacity2}}
          >
            In the meantime you can contact us at: hei@ludoful.fi
          </motion.div>
        </motion.div>

        <motion.div
          layout
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: scrolledTop ? "100vh" : "100px",
            width: "100vw",
            position: "fixed",
            top: "0",
            backgroundColor: colors[randomNumber][1]
          }}
        >
          <motion.div
            layout
            style={{
              fontSize: scrolledTop ? "100px" : "50px",
              fontWeight: "bold"
            }}
          >
            Ludoful
          </motion.div>
          <motion.div
            style={{
              fontSize: "16px",
              textAlign: "center",
              position: "absolute",
              bottom: "50px",
              left: "0",
              right: "0",
              cursor: "pointer",
              visibility: scrolledTop ? "visible" : "hidden",
            }}
            onClick={scrollDown}
          >
            <div>Scroll down</div>
            <div>▼</div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimateSharedLayout>
  </>)
};

export default IndexPage
